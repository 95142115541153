import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  border-radius: 8px 8px 0 0;
  background: ${({ theme }) => theme.colors.white};
  gap: 8px;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 2px 0px #0000001a;
  align-items: center;
  padding: 16px 22px;
  gap: 8px;
  z-index: 1;
`;

const Title = styled.h3`
  flex: 1 0;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    &:first-child {
      color: ${({ theme }) => theme.colors.darkCyan};
    }
    &:last-child {
      font-size: 16px;
      font-weight: 700;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  flex: 0 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkCyan};

  &:hover {
    color: ${({ theme }) => theme.colors.tiffanyBlue};
  }
`;

const BackIcon = styled(Icon)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.darkCyan};
`;

const SC = {
  Container,
  Title,
  Icon,
  Header,
  BackIcon
};

export default SC;
