import { useConfirmDialog } from '@angloeastern/react-library';
import { useFavorite } from '@context/Favorite';
import type { Favorite } from '@context/Favorite/types';
import { useVessels } from '@context/Vessels';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const { setConfirm } = useConfirmDialog();

  const {
    state: { favorites, selected, isLoading },
    setDirty,
    setActiveItem,
    select,
    setIsFormOpen
  } = useFavorite();

  const { setFavorite } = useVessels();

  const onSelect = (guid: string) => {
    if ((guid && !selected) || (selected && guid !== selected.guid)) {
      const fav = guid
        ? favorites.find((x: Favorite) => x.guid === guid)
        : null;
      setDirty(false);
      setFavorite(fav);
      setActiveItem(fav);
      select(fav);
      if (fav) setIsFormOpen(false);
    }
  };

  const onRemove = () => {
    setConfirm(true);
  };

  return {
    ready: !(!favorites || !favorites.length),
    label: t('favorites.loadFavorite'),
    selected: selected ? selected.guid : null,
    favorites: favorites || [],
    showControls: !!selected,
    isLoading,
    onSelect,
    onRemove
  };
};

export default useViewModel;
