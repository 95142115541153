import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import { useSystemNotifications } from '@components/Layout/SystemNotifications/context';
import type { NotificationMessage } from '@components/Layout/SystemNotifications/context/types';
import { useDateUtils } from '@hooks/useDateUtils';
import useTimeStamp from '@hooks/useTimeStamp';
import { useTranslator } from '@hooks/useTranslator';
import { ICON_MAPPING } from './iconMapping';
import SC from './style';
import useMessageGroup from './useMessageGroup';

interface Props {
  message: NotificationMessage;
  prevTimestamp: string;
  index: number;
}
const MessageItem = ({ message, prevTimestamp, index }: Props) => {
  const navigate = useNavigate();
  const { readMessage, setShow } = useSystemNotifications();
  const { t } = useTranslator('notifications', 'label');
  const timestamp = useTimeStamp(message.timestampUtc);
  const { formatDate } = useDateUtils();

  const timeGroup = useMessageGroup(message.timestampUtc);
  const prevGroup = useMessageGroup(prevTimestamp);

  const dateTimestamp =
    timeGroup === t('today') ? timestamp : formatDate(message.timestampUtc);

  const ListItem = message.isRead ? SC.ReadItem : SC.UnreadItem;

  const onClick = () => {
    readMessage(message.notificationGuid);
    navigate(`/${message.query}`);
    setShow(false);
  };

  return (
    <>
      {(index === 0 || timeGroup !== prevGroup) && (
        <SC.Group>{timeGroup}</SC.Group>
      )}
      <ListItem key={message.notificationGuid} onClick={onClick}>
        <SC.IconWrapper>
          <FontAwesomeIcon icon={ICON_MAPPING[message.type]} />
        </SC.IconWrapper>
        <SC.MessageDetail>
          <SC.MessageWrapper>
            <SC.Title>{message.title}</SC.Title>
            <SC.MessageTime>{dateTimestamp}</SC.MessageTime>
          </SC.MessageWrapper>
          <SC.Message>{message.message}</SC.Message>
        </SC.MessageDetail>
      </ListItem>
    </>
  );
};

export default MessageItem;
