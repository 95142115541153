import { Button, SecondaryButton } from '@angloeastern/react-library';

import SC from '../../style';
import { useViewModel } from './useViewModel';

export const Buttons: React.FC = () => {
  const {
    labels,
    isDisabled,
    inProgress,
    isOffice365,
    handleCancel,
    onUpdate
  } = useViewModel();

  return !isOffice365 ? (
    <SC.ButtonsRow>
      <SecondaryButton version={'v2'} onClick={handleCancel}>
        {labels.cancel}
      </SecondaryButton>
      <Button
        version={'v2'}
        onClick={onUpdate}
        disabled={isDisabled || inProgress}
      >
        {labels.update}
      </Button>
    </SC.ButtonsRow>
  ) : null;
};

export default Buttons;
