import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useConfig } from '@context/Config';
import { Feature, useFeatureToggles } from '@context/FeatureToggles';
import { errorTracker } from '@utils/errorTracker';
import { fetchMessages } from './api';
import {
  CHUNK_SIZE,
  ViewMode,
  type ContextType,
  type NotificationMessage
} from './types';

const Context = createContext({} as ContextType);

let socket: any = null;
let wsconnected = false;

export const Provider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [show, setShow] = useState(false);
  const [messages, setMessages] = useState<NotificationMessage[] | null>(null);
  const [limit, setLimit] = useState<number>(CHUNK_SIZE);
  const [view, setView] = useState(ViewMode.MESSAGES);

  const getMessages = async (signal?: AbortSignal) => {
    try {
      const response = await fetchMessages(signal);
      setMessages(response.data);
    } catch (error: any) {
      if (error.name !== 'AbortError' && error.message !== 'canceled') {
        errorTracker(error);
      }
    }
  };

  const readMessage = (msgId: string) => {
    const messagesCopy = messages ? [...messages] : [];
    if (messagesCopy) {
      const index = messagesCopy.findIndex(
        (msg) => msg.notificationGuid === msgId
      );
      if (index >= 0 && !messagesCopy[index].isRead) {
        messagesCopy[index].isRead = true;
        setMessages(messagesCopy);
      }
    }
  };

  const readAll = () => {
    const messagesCopy = messages ? [...messages] : [];
    if (messagesCopy) {
      messagesCopy.map((msg) => {
        msg.isRead = true;
        return msg;
      });
      setMessages(messagesCopy);
    }
  };

  const viewMore = useCallback(() => {
    if (messages && limit < messages.length) {
      setLimit(limit + CHUNK_SIZE);
    }
  }, [limit, messages]);

  const unreadCount = useMemo(() => {
    if (messages && messages.length) {
      return messages.reduce((count, item) => {
        if (!item.isRead) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return 0;
  }, [messages]);

  const showViewMore = useMemo(
    () => !!(messages && limit < messages.length),
    [limit, messages]
  );

  const showReadAll = useMemo(() => {
    if (messages) {
      let unread = 0;
      const theLimit = limit < messages.length ? limit : messages.length;
      for (let i = 0; i < theLimit; i++) {
        const msg = messages[i];
        if (!msg.isRead) {
          unread++;
        }
      }
      return unread > 0;
    }
    return false;
  }, [limit, messages]);

  return (
    <Context.Provider
      value={{
        messages,
        show,
        unreadCount,
        limit,
        showViewMore,
        showReadAll,
        view,
        setView,
        setLimit,
        setShow,
        getMessages,
        readMessage,
        readAll,
        viewMore
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSystemNotifications = () => {
  const { WSS_SERVER } = useConfig();
  const { isEnabled } = useFeatureToggles();

  useEffect(() => {
    if (WSS_SERVER && isEnabled(Feature.WEBSOCKET) && !socket) {
      socket = new WebSocket(WSS_SERVER);

      socket.onopen = () => {
        wsconnected = true;
      };

      socket.onmessage = (event: any) => {
        console.log('Message', event);
      };

      socket.onclose = () => {
        wsconnected = false;
      };
    }

    return () => {
      wsconnected && socket.close();
    };
  }, [WSS_SERVER]);

  return useContext(Context);
};

export default Provider;
