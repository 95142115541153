export interface NotificationMessage {
  notificationGuid: string;
  message: string;
  isRead: boolean;
  query: string;
  timestampUtc: string;
  title: string;
  type: string;
}

export interface NotificationResponse {
  total: number;
  totalUnread?: number;
  data: NotificationMessage[];
}

export enum ViewMode {
  MESSAGES = 'MESSAGES',
  SETTINGS = 'SETTINGS'
}

export type ContextType = {
  messages: NotificationMessage[] | null;
  show: boolean;
  unreadCount: number;
  limit: number;
  showViewMore: boolean;
  showReadAll: boolean;
  view: ViewMode;
  setView(v: ViewMode): void;
  setLimit(n: number): void;
  setShow(b: boolean): void;
  getMessages(s?: AbortSignal): void;
  readMessage(i: string): void;
  readAll(): void;
  viewMore(): void;
};

export const CHUNK_SIZE = 7;
