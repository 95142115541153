import BluishRipples from '@components/Loading/BluishRipples';
import { useMemo } from 'react';
import { useSystemNotifications } from '../../context';
import MessageItem from './MessageItem';
import SC from './style';
import ViewMoreButtom from './ViewMoreButtom';

const MessageList: React.FC = () => {
  const { limit, messages } = useSystemNotifications();

  const messageItems = useMemo(() => {
    if (!messages || messages.length === 0) return null;
    const theLimit = Math.min(limit, messages.length);

    return messages
      .slice(0, theLimit)
      .map((msg, index) => (
        <MessageItem
          key={msg.notificationGuid}
          message={msg}
          prevTimestamp={messages[index > 0 ? index - 1 : 0].timestampUtc}
          index={index}
        />
      ));
  }, [messages, limit]);

  return messageItems ? (
    <>
      <SC.Container>{messageItems}</SC.Container>
      <SC.Footer>
        <ViewMoreButtom />
      </SC.Footer>
    </>
  ) : (
    <SC.Container>
      <BluishRipples />
    </SC.Container>
  );
};

export default MessageList;
