import { InputType } from '@angloeastern/react-library';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import { useSecurityTab } from '../../context';

export const useViewModel = () => {
  const { t } = useTranslator(TranslationNS.COMMON, 'myProfile');
  const { confirmPassword, setConfirmPassword } = useSecurityTab();

  return {
    label: t('confirmPassword'),
    inputProps: {
      type: InputType.PASSWORD,
      name: 'confirmPassword',
      value: confirmPassword,
      onChange: setConfirmPassword,
      required: true
    }
  };
};
