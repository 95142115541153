import { ToggleSwitch } from '@angloeastern/react-library';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import type { ISetting } from '../context/types';
import SC from './style';

const Setting: React.FC<{
  item: ISetting;
  onSettingChange(
    category: 'byEmail' | 'byUI',
    value: boolean,
    eventType: string
  ): void;
}> = ({ item, onSettingChange }) => {
  const { label, eventType, byEmail, byUI } = item;
  const { t } = useTranslator(TranslationNS.NOTIFICATIONS, 'label.eventType');

  return (
    <SC.Container>
      <SC.Label>{t(label)}</SC.Label>
      <SC.Switches>
        <SC.Switch>
          <ToggleSwitch
            version={'v2'}
            checked={byUI}
            onChange={(value) => onSettingChange('byUI', value, eventType)}
          />
        </SC.Switch>
        <SC.Switch>
          <ToggleSwitch
            version={'v2'}
            checked={byEmail}
            onChange={(value) => onSettingChange('byEmail', value, eventType)}
          />
        </SC.Switch>
      </SC.Switches>
    </SC.Container>
  );
};

export default Setting;
