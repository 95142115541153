import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AlertType,
  useAlertMessages,
  useConfirmDialog
} from '@angloeastern/react-library';
import { useProfile } from '@context/Profile';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import { getSupportedLanguages } from '@utils/index';
import { useProfileTab } from '../context';

const useViewModel = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage }
  } = useTranslator(TranslationNS.COMMON, 'myProfile');
  const { dirty, profile, locale, setDirty } = useProfileTab();
  const { update, closeForm } = useProfile();
  const { setConfirm } = useConfirmDialog();
  const { addAlert } = useAlertMessages();

  const handleChange = (lang: string) => {
    const supported = getSupportedLanguages();
    const pathSplit = pathname.split('/');
    pathSplit.shift();
    if (supported.includes(pathSplit[0])) pathSplit.shift();

    navigate(
      `/${lang === 'en' || lang === '' ? '' : lang + '/'}${pathSplit.join('/')}`
    );
    changeLanguage(lang);
  };

  const onUpdate = () => {
    if (profile) {
      setDirty(false);
      try {
        update({ ...profile, language: locale });
        handleChange(locale);
        addAlert({
          type: AlertType.SUCCESS,
          body: t('updateSuccess')
        });
        // setToastState({
        //   show: true,
        //   message: t('updateSuccess'),
        //   success: true
        // });
      } catch (error) {
        addAlert({
          type: AlertType.ERROR,
          body: t('updateFailed')
        });
        // setToastState({
        //   show: true,
        //   message: t('updateFailed'),
        //   success: false
        // });
      }
    }
  };

  const onCancel = useCallback(() => {
    setConfirm(dirty);
    if (!dirty) {
      closeForm();
    }
  }, [dirty]);

  return {
    labels: {
      cancel: t('cancel'),
      update: t('update')
    },
    onCancel,
    onUpdate
  };
};

export default useViewModel;
