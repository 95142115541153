import { Loaders } from '@angloeastern/react-library';
import BluishRipples from '@components/Loading/BluishRipples';
import SettingItem from './SettingItem';
import Header from './SettingItem/Header';
import SC from './style';
import useViewModel from './useViewModel';

const SettingList: React.FC = () => {
  const { show, loading, data, loaderProps, onSettingChange } = useViewModel();

  if (!show) return null;

  return (
    <>
      <SC.Container>
        <Header />
        {data ? (
          data.map((item, idx) => (
            <SettingItem
              key={idx}
              item={item}
              onSettingChange={onSettingChange}
            />
          ))
        ) : (
          <BluishRipples />
        )}
      </SC.Container>
      <SC.Footer>{loading && <Loaders.Ellipsis {...loaderProps} />}</SC.Footer>
    </>
  );
};

export default SettingList;
