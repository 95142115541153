import { faGear } from '@fortawesome/pro-regular-svg-icons';
import { faSquareChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import { useSystemNotifications } from '../../context';
import { ViewMode } from '../../context/types';
import ReadAllButton from '../MessageList/ReadAllButton';
import SC from './style';

const SettingsTitle = () => {
  const { t } = useTranslator(TranslationNS.NOTIFICATIONS);

  return (
    <>
      <span>{t(`label.notifications`)}</span>
      <span>{t(`label.subscriptionsSettings`)}</span>
    </>
  );
};

const Header: React.FC = () => {
  const { t } = useTranslator(TranslationNS.NOTIFICATIONS);
  const { view, setView } = useSystemNotifications();

  return (
    <SC.Container>
      <SC.Header>
        {view === ViewMode.SETTINGS && (
          <SC.BackIcon
            icon={faSquareChevronLeft}
            onClick={() => {
              setView(ViewMode.MESSAGES);
            }}
          ></SC.BackIcon>
        )}
        <SC.Title>
          {view === ViewMode.SETTINGS ? (
            <SettingsTitle />
          ) : (
            t('label.notifications')
          )}
        </SC.Title>
        {view === ViewMode.MESSAGES && (
          <>
            <ReadAllButton />
            <SC.Icon
              icon={faGear}
              onClick={() => {
                setView(ViewMode.SETTINGS);
              }}
            />
          </>
        )}
      </SC.Header>
    </SC.Container>
  );
};

export default Header;
