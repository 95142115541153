import type { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import {
  faDollarSign,
  faFileCertificate,
  faFileChartPie,
  faFileLines,
  faShip,
  faUsers
} from '@fortawesome/pro-light-svg-icons';

export const ICON_MAPPING: Record<string, IconDefinition> = {
  vessel: faShip,
  crew: faUsers,
  opex: faDollarSign,
  certificates: faFileCertificate,
  reports: faFileChartPie,
  documents: faFileLines
};
