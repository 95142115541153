import { useDetectOutsideClick } from '@angloeastern/react-library';
import { useRef } from 'react';

import { useSystemNotifications } from '../context';
import { ViewMode } from '../context/types';
import Header from './Header';
import MessageList from './MessageList';
import SettingList from './SettingList';
import SC from './style';

const Display: React.FC = () => {
  const { view, show, setShow } = useSystemNotifications();
  const notifRef = useRef(null);
  useDetectOutsideClick(notifRef, show, () => setShow(false), 100);

  return show ? (
    <SC.Container ref={notifRef}>
      <Header />
      {view === ViewMode.MESSAGES ? <MessageList /> : <SettingList />}
    </SC.Container>
  ) : null;
};

export default Display;
