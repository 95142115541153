import { theme } from '@angloeastern/react-library';

const cellStyles = `
  padding: 14px 24px 14px 12px;
  font-size: 13px;
  line-height: 20px;
  background: white;
  text-align: right;
`;

const tableHeadStyles = `
  ${cellStyles}
  text-wrap: nowrap;
  font-weight: bold;
  width: 12%;
  max-width: 12%;
  min-width: 12%;

  &:first-child {
    width: 28%;
    max-width: 28%;
    min-width: 28%;
  }
`;

const appTheme = {
  colors: {
    fileViewerBackground: `${theme.colors.eerieBlack}cc`,
    formBorder: `${theme.colors.spanishGray}`,
    formLabel: `${theme.colors.darkGray}`,
    formValue: `${theme.colors.quartz}`,
    fosGray: `${theme.colors.whispher}`,
    kpiTitleGray: `${theme.colors.gray}`,
    loaderBlue: `${theme.colors.veryDarkBlue}bf`,
    opexSubLevel: `${theme.colors.whiteSmokeF4}`,
    sliderBackground: `${theme.colors.black}80`,
    successGreen: `${theme.colors.mantis}`,
    timelineMonthBar: `${theme.colors.onyx}1a`,
    supyCrewBackground: `${theme.colors.whispher}`,
    angloeastern: `${theme.colors.brightYellow}`
  },
  headings: {
    messageTitle: `
      text-align: center;
      border-bottom: 1px solid ${theme.colors.gainsboro};
      padding: 20px 0 12px 0;
      margin: 0;
      flex: 0 0;    
    `,
    name: `
      margin: 0;
      padding: 0 0 22px 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: ${theme.colors.darkCharcoal};
    `
  },
  icons: {
    topBarCorner: `
      display: none;

      svg {
        font-size: 20px;
    
        > path {
          fill: ${theme.colors.brightYellow};
        }
      }
    
      @media only screen and (min-width: 768px) {
        display: flex;
        flex: 0 0 24px;
        width: 24px;
        padding: 0 16px;
        justify-content: center;
      }
    `
  },
  scrollbar: `
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 10px;
      border: 2px solid #555;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    }

    ::-webkit-scrollbar:horizontal {
      height: 6px; /* Adjust height as needed */
    }

    ::-webkit-scrollbar-track:horizontal {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      background-color: #888;
      border-radius: 10px;
      border: 2px solid #555;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    }
  `,
  table: {
    cell: `
      ${cellStyles}
      color: ${theme.colors.trout};
    `,
    expandIcon: `
      flex: 0 0 12px;
      cursor: pointer;
      width: 12px;
      height: 12px;
      color: ${theme.colors.black};
      align-self: center;
    `,
    header: `
      ${tableHeadStyles}
      color: ${theme.colors.weldonBlue};

      &:first-child {
        text-align: left;
        padding-left: 10px;
      }
    `,
    footer: `
      ${tableHeadStyles}
      border-top: 1px solid ${theme.colors.antiFlashWhite};
      color: ${theme.colors.trout};

      &:first-child {
        font-weight: normal;
        text-align: center;
      }
    `
  },
  text: {
    redNegativeValue: `
      color: ${theme.colors.flamingo};
      &:before {
        content: '(';
      }
      &:after {
        content: ')';
      }
    `,
    shadow: {
      blackHalo: `
        text-shadow:
          0 0 5px rgba(0, 0, 0, 0.5),
          0 0 10px rgba(0, 0, 0, 0.4),
          0 0 15px rgba(0, 0, 0, 0.3),
          0 0 20px rgba(0, 0, 0, 0.2),
          0 0 25px rgba(0, 0, 0, 0.1);
      `,
      licorice: `
        text-shadow: 1px 1px ${theme.colors.licorice};
      `
    },
    topBarCornerLabel: `
      flex: 1 0;
      background: transparent;
      align-self: center;
      font-size: 14px;
      font-weight: 700;
      color: white;

      @media only screen and (min-width: 768px) {
        color: ${theme.colors.licorice};
      }
    `
  }
};

export default appTheme;
