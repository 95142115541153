import { InputType } from '@angloeastern/react-library';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import { useSecurityTab } from '../../context';

export const useViewModel = () => {
  const { t } = useTranslator(TranslationNS.COMMON, 'myProfile');
  const { newPassword, setNewPassword } = useSecurityTab();

  return {
    label: t('newPassword'),
    inputProps: {
      type: InputType.PASSWORD,
      name: 'newPassword',
      value: newPassword,
      onChange: setNewPassword,
      required: true
    }
  };
};
