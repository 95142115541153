import { useAuthUser } from '@context/AuthUser';
import { useProfile } from '@context/Profile';
import { updatePassword } from '@context/Profile/api';
import useEncryption from '@hooks/useEncryption';
import useMessageBoxPresets from '@hooks/useMessageBoxPresets';
import { useTranslator } from '@hooks/useTranslator';
import { errorTracker } from '@utils/errorTracker';
import { createContext, useContext, useEffect, useState } from 'react';

const Context = createContext({});
const regex =
  /^(?=[^.-])(?=.*[a-z])(?=.*[A-Z])(?=.*[`!@#$%^&*()+=_-{}[\]\\|:;”’?/<>,.])(?=.{12,})/;

export const Provider: React.FC<{ children: any }> = ({ children }) => {
  const { t } = useTranslator();
  const { isOffice365 } = useAuthUser();
  const { showSuccess, showFailure } = useMessageBoxPresets();
  const [inProgress, setInProgress] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const { closeForm } = useProfile();
  const { encrypt } = useEncryption();

  const clearFields = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleCancel = () => {
    closeForm();
    clearFields();
  };

  const validate = () => {
    if (newPassword !== confirmPassword) {
      showFailure('myProfile.passwordMustBeSame');
      return false;
    } else if (!regex.test(newPassword)) {
      showFailure('myProfile.passwordErrorMessage');
      return false;
    }
    return true;
  };

  const onUpdate = async () => {
    if (validate()) {
      setInProgress(true);
      try {
        await updatePassword(encrypt(currentPassword), encrypt(newPassword));
        clearFields();
        showSuccess('myProfile.updateSuccess');
      } catch (error: any) {
        if (error?.response?.status === 400 && error?.response?.data)
          showFailure('myProfile.updateError', {
            details: error?.response?.data
          });
        else showFailure('myProfile.updateFailed');
        errorTracker(error);
      }
      setInProgress(false);
    }
  };

  useEffect(() => {
    setIsDisabled(!newPassword || !confirmPassword || !currentPassword);
  }, [currentPassword, newPassword, confirmPassword]);

  return (
    <Context.Provider
      value={{
        t,
        isDisabled,
        currentPassword,
        confirmPassword,
        newPassword,
        inProgress,
        isOffice365,
        setIsDisabled,
        setCurrentPassword,
        setConfirmPassword,
        setNewPassword,
        handleCancel,
        onUpdate
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSecurityTab = () => {
  return useContext<any>(Context);
};
